/**
 * Module dependencies.
 */

import { Image, RouterLink, Svg, icons } from 'src/components/core';
import { ReactElement } from 'react';
import { Type, color, media, units } from '@untile/react-components';
import { useLocalizedRoute } from 'src/core/utils/routes';
import head from 'lodash/head';
import styled from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  description: string;
  imageUrl: string;
  slug: string;
  title: string;
};

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(Image)`
  transition: transform 0.3s ease;
  will-change: transform;
`;

/**
 * `TextWrapper` styled component.
 */

const TextWrapper = styled.div`
  height: 100%;
  padding: ${units(2)} ${units(2)} 0 ${units(2)};
  transition: transform 0.4s ease;
  will-change: transform;

  ${media.min('sm')`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content 1fr max-content;
  `}
`;

/**
 * `Icon` styled component.
 */

const Icon = styled(Svg)`
  padding-top: ${units(1)};
  transition: transform 0.4s ease;
  will-change: transform;
`;

/**
 * `Card` styled component.
 */

const Card = styled(RouterLink)`
  color: ${color('textColor')};
  display: flex;
  flex-direction: column;
  height: 100%;
  outline: none;
  position: relative;
  text-decoration: none;
  z-index: 1;

  &:focus,
  &:hover {
    ${StyledImage} {
      transform: scale(0.98);
    }

    ${TextWrapper} {
      transform: scale(0.965);
    }

    ${Icon} {
      transform: translateX(5px);
    }
  }
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  overflow: hidden;
  padding-bottom: 73%;
  position: relative;
`;

/**
 * `NewsExhibitionsCard` component.
 */

const NewsExhibitionsCard = (props: Props): ReactElement => {
  const { locale } = useTranslate();
  const { className, description, imageUrl, slug, title } = props;
  const maxLength = 170;

  const routeResolver = useLocalizedRoute();

  return (
    <Card
      className={className}
      href={routeResolver('newExhibitionDetails', { slug })}
      locale={locale}
    >
      <ImageWrapper>
        <StyledImage
          alt={title}
          fill
          src={head(imageUrl)}
          style={{ objectFit: 'cover' }}
        />
      </ImageWrapper>

      <TextWrapper>
        <Type.H4 marginBottom={units(2)}>{title}</Type.H4>

        <Type.Paragraph>{description.length > maxLength ? `${description.substring(0, maxLength)}...` : description}</Type.Paragraph>

        <Icon
          icon={icons.arrowRightLarge}
          size={units(6)}
        />
      </TextWrapper>
    </Card>
  );
};

/**
 * Export `NewsExhibitionsCard` component.
 */

export default NewsExhibitionsCard;
